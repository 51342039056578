<div mat-dialog-title class="d-flex align-items-center">
    <div class="d-flex flex-column">
        <h5 class="sy-overline">{{dialogSubtitle}}</h5>
        <h2 class="sy-headline-6">{{dialogTitle}}</h2>
    </div>
    <button mat-icon-button mat-dialog-close class="ms-auto">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <!-- Injected content -->
    <ng-content select=":not(mat-dialog-actions)"></ng-content>
</mat-dialog-content>

<!-- Default actions -->
@if (!customActions) {
<mat-dialog-actions>
    <button mat-stroked-button class="flex-fill" color="primary" mat-dialog-close>Cancel</button>
    <button mat-flat-button class="flex-fill" color="primary" [disabled]="saveDisabled" preventDoubleClick
        (throttledClick)="saveAction.emit()">{{saveButtonLabel}}</button>
</mat-dialog-actions>
}

<!-- Injected actions -->
<ng-content select="mat-dialog-actions"></ng-content>

<!-- Loading Bar -->
@if(loading){
<mat-card-footer>
    <mat-progress-bar color="accent" mode="indeterminate" />
</mat-card-footer>
}